import Vue from "vue";
import App from "./App.vue";
import router from "./router"; // 路由
import store from "./store/index"; // vuex
import { v3 } from "@/api"; // vuex
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import axios from 'axios';
// countries: (this.$store.state.base.LanguageCode != 'zh-cn' && this.$store.state.base.LanguageCode != 'zh' && this.$store.state.base.LanguageCode != 'zh-tw' && this.$store.state.base.LanguageCode != 'zh-hk') ? require('../../static/json/areaCodeEn.json') : require('../../static/json/areaCodeCN.json'), // 中文国家列表

import {
  Toast,
} from "vant";
import "vant/lib/index.css";
import "@/assets/css/tailwind.css";
import "@/assets/css/reset.css";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(Vant);
Vue.prototype.$Toast = Toast;
Vue.prototype.$axios = axios;
// import 'lib-flexible'

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.prototype.$cookies = Cookies; // 设置cookie
Vue.prototype.$store = store; // vuex
Vue.prototype.$v3 = v3; // vuex

const commit = Vue.prototype.$store;
// 处理域名
if (window.location.host.includes(".com")) {
  // 设置获取主域名
  const host = window.location.host.split(".").splice(1, 2);
  commit.commit("base/sethost", host.join("."));
}
let getdata = {};
// const vux = commit.state.base;
// console.log(appagentparam, 8866555)

let lang = ""; // 定义语言
const langList = store.state.base.langList;
// console.log('langList: ', langList);
lang = window.location.href
  .replace("http://", "")
  .replace("https://", "")
  .split("/")[1]
  .split("?")[0];
console.log("lang: ", lang);
// ---取浏览器语言
let navlang = navigator.language.split(",")[0].toLowerCase();
console.log("navlang: ", navlang);
if (navlang != "zh-cn" && navlang.includes("-")) {
  navlang = navlang.split("-")[0];
}
if (!langList.includes(navlang)) navlang = "en";
console.log(
  "store.state.base.langList.includes(lang): ",
  store.state.base.langList.includes(lang),
);

// ---------------------------------
lang =
  lang && store.state.base.langList.includes(lang)
    ? lang.toLocaleLowerCase()
    : navlang
      ? navlang
      : "en";
if (lang === "zh-tw" || lang === "zh-hk") {
  lang = "zh";
  window.location.href = `/${lang}`;
}

  commit.commit("base/setLangCode", lang); // 存入当前语言

if (getdata.userId) commit.commit("base/setUserid", getdata.userId); // 设置userid

// ...对数据进行存储

// const i18n = new VueI18n({
//   locale: lang,
//   messages,
//   silentTranslationWarn: true,
// });
// VueI18n.prototype.translation = function (value) {
//   let en = require("@/assets/languages/TXT_zh-CN.json");
//   let val = findKey(en, function (o) {
//     return o === value;
//   });
//   if (!val) {
//     return value + "(前端未翻译)";
//   }
//   return this.$t(val);
// };
// Vue.prototype.$v = i18n.translation; // i18n
document.querySelector("html").setAttribute("lang", lang);
// i18n.location = lang;
if (lang === "ar") {
  document.querySelector("html").setAttribute("dir", "rtl");
} else {
  document.querySelector("html").setAttribute("dir", "ltr");
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
