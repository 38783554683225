const webUrl = "/wikifxweb-v1/invoke-v3/";
// const appUrl = "/wikifxapp-v1/invoke-v3/";
// const url = '/wikifxweb-v1/invoke-v3/{customKey}';

export function v3 (data, headers) {
  try {
    const o = Object.values(headers);
    const v = o[0].split('$')[0];
    return request({
      url: webUrl + v,
      method: 'post',
      data,
      headers
    });
  } catch(err) {
    console.log('v3接口异常,data:' + JSON.parse(data) + 'header:' + JSON.parse(headers))
  }
}